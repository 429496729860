<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="6">
                <page-title
                        title="Histórico de envios"
                        :subtitle="'A mostrar '+historicSales.length+' linhas'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="clearFilters()" color="error">
                    Limpar Filtros
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th width="80px">{{ $t('departureDate') }}</th>
                            <th>Cliente</th>
                            <th width="110px">Faturas</th>
                            <th width="70px">Transp.</th>
                            <th width="110px">Referência</th>
                            <th width="150px">Ref. Transp.</th>
                            <th style="min-width: 170px;">Estado</th>
                            <th width="80px" class="text-center">Reembolso</th>
                            <th width="50px" class="text-center">Anulação Reembolso</th>
                            <th width="80px" class="text-center">Valor</th>
                            <th width="80px" class="text-center">Novo Valor</th>
                            <th width="60px" class="text-center">Conferido</th>
                            <th width="80px" class="text-center">Recibo</th>
                            <th width="20px"></th>
                            <th width="20px"></th>
                            <th width="20px">Desenfalg.</th>
                            <th width="84px" v-if="writePermission('orders/shipped')"></th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.date"
                                        outlined
                                        dense
                                        hide-details
                                        single-line
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.entity"
                                        outlined
                                        dense
                                        hide-details
                                        single-line
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.invoice"
                                        outlined
                                        dense
                                        hide-details
                                        single-line
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.courier"
                                        outlined
                                        dense
                                        hide-details
                                        single-line
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.reference"
                                        outlined
                                        dense
                                        hide-details
                                        single-line
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.tracking"
                                        outlined
                                        dense
                                        hide-details
                                        single-line
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.reembolso"
                                        outlined
                                        hide-details
                                        dense
                                        :items="filtersYesNo"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        @change="filterTable()"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.reembolsoUpdate"
                                        outlined
                                        hide-details
                                        dense
                                        :items="filtersYesNo"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        @change="filterTable()"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.price"
                                        outlined
                                        hide-details
                                        dense
                                        @keyup.enter="filterTable()"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.checked"
                                        outlined
                                        hide-details
                                        dense
                                        :items="filtersYesNo"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        @change="filterTable()"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.recibo"
                                        outlined
                                        hide-details
                                        dense
                                        :items="filtersYesNo"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        @change="filterTable()"
                                ></v-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td v-if="writePermission('orders/shipped')"></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="16">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="historic in historicSales">
                            <td>{{ historic.created_at }}</td>
                            <td>{{ historic.clientCode }} - {{ historic.clientName }}</td>
                            <td>{{ historic.docs }}</td>
                            <td>{{ historic.courier }}</td>
                            <td>{{ historic.reference }}</td>
                            <td>
                                <v-container v-if="historic.trackingNumber" style="padding: 12px 6px;">
                                    {{ historic.trackingNumber.replaceAll(";", ' ') }}
                                    <template
                                            v-if="(historic.courier === 'SEUR' || historic.courier === 'SEUR2') && historic.trackingNumber !== null">
                                        <a v-if="historic.clientCode.startsWith('ES')"
                                           :href="`https://dpd.pt/track-and-trace?reference=${historic.trackingNumber}`"
                                           target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                                        <a v-else
                                           :href="`https://dpd.pt/track-and-trace?reference=${historic.trackingNumber}`"
                                           target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                                    </template>
                                    <a v-if="historic.courier === 'CTT' && historic.trackingNumber !== null"
                                       :href="`http://www.ctt.pt/feapl_2/app/open/cttexpresso/objectSearch/objectSearch.jspx?objects=${historic.trackingNumber}`"
                                       target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                                    <a v-if="historic.courier === 'NACEX' && historic.trackingNumber !== null"
                                       :href="`https://www.nacex.es/seguimientoDetalle.do?agencia_origen=${(historic.expeditionNumber).split('/')[0]}&numero_albaran=${(historic.expeditionNumber).split('/')[1]}`"
                                       target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                                    <a v-if="historic.courier === 'REDUR' && historic.trackingNumber !== null && historic.status !== ''"
                                       :href="`https://redur.es/pt-pt/track-trace/?idioma=pt&buscarPor=NTRACKING&valor=${historic.trackingNumber}`"
                                       target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                                </v-container>
                            </td>
                            <td class="text-uppercase">
                                <span v-if="historic.status" style="padding: 4px 8px; border-radius: 4px;" :style="[historic.status == 'ENTREGUE' || historic.status == 'OK' || historic.status == 'DEL' || historic.status == 'PROVA DE ENTREGA' || historic.status == 'Entrega Conseguida' ? {'background-color': 'limegreen'} : ( historic.status == 'Destinatário Ausente' || historic.status == 'CAN' || historic.status == 'INCIDENCIA RECOGIDA' || historic.status == 'INCIDENCIA' || historic.status.substring(0,10) == 'SOL SIN OK' ?  {'background-color': 'red'} : {'background-color': 'gold'})]">
                                    {{ historic.courier == 'TRANSNAUTICA' ? mapStatusTransnautica(historic.status) : historic.status }}
                                </span>
                            </td>
                            <td class="text-center">
                                <span v-if="historic.payment !== 'F'">{{ $t('no') }}</span>
                                <span v-if="historic.payment === 'F'">{{ $t('yes') }}</span>
                            </td>
                            <td class="text-center">
                                <span>
                                    {{ historic.paymentCancelFlag ? 'Sim' : ' Não' }}
                                </span>
                            </td>
                            <td class="text-right">{{ $money(historic.price) }}</td>
                            <td class="text-center">
                                {{ historic.paymentUpdatePrice != null ? $money(historic.paymentUpdatePrice) : ' - ' }}
                            </td>
                            <td class="text-center" :class="{'green lighten-4': historic.checked}">
                                <span v-if="historic.checked === 0">{{ $t('no') }}</span>
                                <span v-if="historic.checked === 1">{{ $t('yes') }}</span>
                                <!--                        <v-btn v-if="historic.checked === 1" class="mx-2" x-small tile outlined color="green">-->
                                <!--                            {{ $t('yes') }}-->
                                <!--                        </v-btn>-->
                            </td>
                            <td class="text-center" :class="{'green lighten-4': historic.recibo}">{{
                                    historic.recibo
                                }}
                            </td>
                            <td>
                                <i v-if="historic.notes" class="fas fa-box-open pink--text"></i>
                            </td>
                            <td>
                                <i v-if="historic.notesPri" class="fab fa-product-hunt indigo--text"></i>
                            </td>
                            <td class="text-center">
                                <span v-if="historic.courier != 'SEUR2'">-</span>
                                <template v-else>
                              <span v-if="historic.emailToDispatch == true">
                                <i style="color: green" class="fas fa-check fa-2x"></i>
                              </span>
                                    <span v-else>
                                <i style="color: red" class="fas fa-times fa-2x"></i>
                              </span>
                                </template>
                            </td>
                            <td v-if="writePermission('orders/shipped')">
                                <v-btn tile small color="secondary" @click="toggleDialogEditShipped(historic)">{{
                                        $t('detail')
                                    }}
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <v-row>
                        <v-col class="text-left">
                            <v-select
                                    v-model="pagination.per_page"
                                    :items="filtersPerPage"
                                    outlined
                                    single-line
                                    hide-details
                                    @change="updatePerPage"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    class="staff_select ml-2 mb-2"
                                    style="width: 90px"
                            ></v-select>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn tile small :disabled="pagination.page <= 1  || loading" class="ma-1"
                                   @click="updatePage('anterior')">Anterior
                            </v-btn>
                            <v-btn tile small color="secondary" class="ma-1" style="min-width: 34px;">{{
                                    pagination.page
                                }}
                            </v-btn>
                            <v-btn tile small :disabled="historicSales.length !== pagination.per_page || loading"
                                   class="ma-1"
                                   @click="updatePage('seguinte')">Seguinte
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">
            <v-dialog v-model="dialogDetail" persistent width="990">
                <v-card>
                    <v-toolbar flat dense dark color="primary">
                        <v-toolbar-title class="text-uppercase">
                            {{ $t('detail') }} - {{ editShipped.reference }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn tile small v-if="editShipped.checked === 0" @click="checkShipping()" class="success">
                            Conferido
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pt-4">
                        <v-row>
                            <v-col cols="7">
                                <p>
                                    <strong>Cliente: </strong> {{ editShipped.clientCode }} - {{
                                        editShipped.clientName
                                    }}
                                </p>
                                <p><strong>{{ $t('address1') }}: </strong> {{ editShipped.address }}</p>
                                <p><strong>Código Postal: </strong>{{ editShipped.cp }} - {{ editShipped.cp_locality }}
                                </p>
                                <p>
                                    <strong>Volumes: </strong> {{ editShipped.quantity }}
                                    <span style="margin-left: 32px;"><strong>Peso: </strong> {{
                                            editShipped.weight
                                        }}</span>
                                    <v-btn class="ml-4" v-if="editShipped.hasPackingList" x-small tile color="warning"
                                           @click="dowloadPackingList(editShipped)">packing list
                                    </v-btn>
                                </p>
                                <p class="mb-0">
                                    <strong>Notas do Primavera: </strong>
                                    <v-textarea
                                            v-model="editShipped.notesPri"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-textarea>
                                </p>
                            </v-col>
                            <v-col cols="5">
                                <p>
                                    <strong>Reembolso: </strong>
                                    <span v-if="editShipped.payment !== 'F'">{{ $t('no') }}</span>
                                    <span v-if="editShipped.payment === 'F'">{{ $t('yes') }}</span>
                                    <span style="margin-left: 32px;" v-if="editShipped.payment === 'F'">
                                    <strong>Valor: </strong> {{ $money(editShipped.price) }}</span>
                                </p>
                                <p><strong>Documentos: </strong> {{ editShipped.docs }}</p>
                                <p>
                                    <strong>Recibo: </strong>
                                    <v-text-field
                                            dense
                                            v-model="editShipped.recibo"
                                            single-line
                                            outlined
                                            hide-details
                                    ></v-text-field>
                                </p>
                                <p class="mb-0">
                                    <strong>Notas do Envio: </strong>
                                    <v-textarea
                                            v-model="editShipped.notes"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-textarea>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row v-if="writePermission('orders/shipped/checks')">
                            <v-col cols="2">
                                <v-checkbox v-model="editShipped.payCheck" label="Cheque"></v-checkbox>
                            </v-col>
                            <v-col cols="3" v-if="editShipped.payCheck">
                                <p>
                                    <strong>Num. Conta: </strong>
                                    <v-text-field
                                            dense
                                            v-model="editShipped.checkNumber"
                                            single-line
                                            outlined
                                            hide-details
                                            id="input_check_number"
                                            v-on:keyup.enter="$refs.input_check_account.focus()"
                                    ></v-text-field>
                                </p>
                            </v-col>
                            <v-col cols="3" v-if="editShipped.payCheck">
                                <p>
                                    <strong>Num. Cheque: </strong>
                                    <v-text-field
                                            dense
                                            v-model="editShipped.checkAccount"
                                            single-line
                                            outlined
                                            hide-details
                                            ref="input_check_account"
                                            v-on:keyup.enter="$refs.input_check_value.focus()"
                                    ></v-text-field>
                                </p>
                            </v-col>
                            <v-col cols="2" v-if="editShipped.payCheck">
                                <p>
                                    <strong>Valor: </strong>
                                    <v-text-field
                                            dense
                                            v-model="editShipped.checkValue"
                                            single-line
                                            outlined
                                            hide-details
                                            ref="input_check_value"
                                            v-on:keyup.enter="$refs.input_check_date.focus()"
                                    ></v-text-field>
                                </p>
                            </v-col>
                            <v-col cols="2" v-if="editShipped.payCheck">
                                <p>
                                    <strong>Data: </strong>
                                    <v-text-field
                                            dense
                                            v-model="editShipped.checkDate"
                                            single-line
                                            outlined
                                            hide-details
                                            ref="input_check_date"
                                    ></v-text-field>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row v-if="editShipped.payment == 'F'">
                            <v-col :cols="editShipped.paymentCancelDate != null ? 9 : 4">
                                <div v-if="editShipped.paymentCancelDate != null">
                                    <i class='fas fa-money-bill' style='font-size:18px;color:darkgreen'></i><span> Reembolso anulado por </span><strong
                                        style="text-decoration: underline">{{ editShipped.paymentCancelLogin }}</strong><span> a pedido de </span>
                                    <strong style="text-decoration: underline">"{{
                                            editShipped.paymentCancelRequester
                                        }}"</strong>
                                </div>
                                <v-checkbox v-else v-model="temporaryCancelFlag" label="Anular reembolso"></v-checkbox>
                            </v-col>
                            <v-col cols="3" v-if="temporaryCancelFlag && editShipped.paymentCancelDate == null">
                                <p>
                                    <strong>Requisitante: </strong>
                                    <v-text-field
                                            dense
                                            v-model="temporaryCancelRequester"
                                            single-line
                                            :rules="textBoxRules"
                                            outlined
                                            hide-details
                                    ></v-text-field>
                                </p>
                            </v-col>
                            <v-col cols="4"
                                   v-if="temporaryCancelFlag && editShipped.paymentCancelDate == null && editShipped.courier == 'CTT'">
                                <p>
                                    <strong>Email transportadora: </strong>
                                    <v-text-field
                                            dense
                                            v-model="temporaryCTTemail"
                                            min="0"
                                            single-line
                                            :rules="emailRules"
                                            outlined
                                            hide-details
                                    ></v-text-field>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row v-if="editShipped.payment == 'F'">
                            <v-col :cols="editShipped.paymentUpdatePrice != null ? 9 : 4">
                                <div v-if="editShipped.paymentUpdatePrice != null">
                                    <i class='fas fa-money-bill' style='font-size:18px;color:darkgreen'></i><span> Reembolso alterado para <span
                                        style="text-decoration: underline">{{ editShipped.paymentUpdatePrice }}€</span> por </span><strong
                                        style="text-decoration: underline">{{ editShipped.paymentUpdateLogin }}</strong><span> a pedido de </span>
                                    <strong style="text-decoration: underline">"{{
                                            editShipped.paymentUpdateRequester
                                        }}"</strong>
                                </div>
                                <v-checkbox v-else v-model="temporaryNewValueFlag"
                                            label="Alterar valor reembolso"></v-checkbox>
                            </v-col>
                            <v-col cols="8" v-if="temporaryNewValueFlag && editShipped.paymentUpdatePrice == null">
                                <v-row>
                                    <v-col>
                                        <p>
                                            <strong>Novo valor: </strong>
                                            <v-text-field
                                                    type="number"
                                                    dense
                                                    v-model.number="temporaryPaymentUpdatePrice"
                                                    min="0"
                                                    single-line
                                                    outlined
                                                    hide-details
                                                    append-icon="euro_symbol"
                                            ></v-text-field>

                                        </p>
                                    </v-col>
                                    <v-col>
                                        <p>
                                            <strong>Requisitante: </strong>
                                            <v-text-field
                                                    dense
                                                    v-model="temporaryPaymentUpdateRequester"
                                                    min="0"
                                                    single-line
                                                    :rules="textBoxRules"
                                                    outlined
                                                    hide-details
                                            ></v-text-field>

                                        </p>
                                    </v-col>
                                    <v-col v-if="editShipped.courier == 'CTT'">
                                        <p>
                                            <strong>Email transportadora: </strong>
                                            <v-text-field
                                                    dense
                                                    v-model="temporaryCTTemail"
                                                    min="0"
                                                    :rules="emailRules"
                                                    single-line
                                                    outlined
                                                    hide-details
                                            ></v-text-field>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-3">
                        <v-btn tile small @click="dialogDetail = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="updateEditShipped()"
                               :disabled="paymentCanceledFlag || paymentUpdatedFlag">
                            <!--                               :disabled="(temporaryCancelFlag == true && ( temporaryCancelRequester == null || temporaryCancelRequester == '' || (editShipped.courier == 'CTT' && (temporaryCTTemail == null || temporaryCTTemail == '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(temporaryCTTemail)))))) || (temporaryNewValueFlag == true && ( temporaryPaymentUpdatePrice == null || temporaryPaymentUpdatePrice == '' || temporaryPaymentUpdateRequester == null || temporaryPaymentUpdateRequester == '' || (editShipped.courier == 'CTT' && (temporaryCTTemail == null || temporaryCTTemail == '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(temporaryCTTemail))))))" -->
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>
export default {
    data: () => ({
        validForm: false,
        textBoxRules: [
            v => !!v || 'Obrigatório!',
            v => (v != null && v.length > 0) || 'Obrigatório!'
        ],
        emailRules: [
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'E-mail must be valid'
        ],
        temporaryCTTemail: null,
        temporaryNewValueFlag: null,
        temporaryPaymentUpdatePrice: null,
        temporaryPaymentUpdateRequester: null,
        temporaryCancelFlag: null,
        temporaryCancelRequester: null,
        loading: false,
        headers: [],
        historicSales: [],
        filters: {
            date: '',
            entity: '',
            name: '',
            invoice: '',
            courier: '',
            reference: '',
            tracking: '',
            checked: '',
            reembolso: '',
            reembolsoUpdate: '',
            price: '',
            recibo: ''
        },
        pagination: {
            page: 1,
            per_page: 50
        },
        filtersPerPage: [
            {TEXT: '25', VALUE: 25},
            {TEXT: '50', VALUE: 50},
            {TEXT: '100', VALUE: 100},
            {TEXT: '250', VALUE: 250},
            {TEXT: '500', VALUE: 500}
        ],
        dialogDetail: false,
        editShipped: {},
        filtersYesNo: [
            {TEXT: 'Todos', VALUE: ''},
            {TEXT: 'Não', VALUE: '0'},
            {TEXT: 'Sim', VALUE: '1'}
        ],
    }),
    created() {
        let courier = this.$route.query.filter;
        if (courier) {
            this.setFiltersCourier(courier);
        }
        document.title = 'Histórico de envios';
        this.initialize()
    },
    computed: {
        paymentCanceledFlag: function () {
            return this.editShipped.paymentCancelDate == null && (this.temporaryCancelFlag == true && (this.temporaryCancelRequester == null || this.temporaryCancelRequester == '' || (this.editShipped.courier == 'CTT' && (this.temporaryCTTemail == null || this.temporaryCTTemail == '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(this.temporaryCTTemail))))));
        },
        paymentUpdatedFlag: function () {
            return this.editShipped.paymentUpdatePrice == null && (this.temporaryNewValueFlag == true && (this.temporaryPaymentUpdatePrice == null || this.temporaryPaymentUpdatePrice == '' || this.temporaryPaymentUpdateRequester == null || this.temporaryPaymentUpdateRequester == '' || (this.editShipped.courier == 'CTT' && (this.temporaryCTTemail == null || this.temporaryCTTemail == '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(this.temporaryCTTemail))))));
        }
    },
    methods: {
        filterTable() {
            this.pagination.page = 1;
            this.initialize();
        },
        initialize() {
            this.loading = true;
            this.historicSales = [];
            let request = {
                url: api.orderHistoricShipped(),
                data: {
                    filters: this.filters,
                    pagination: this.pagination
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.headers = responseData.headers
                            this.historicSales = responseData.shipped
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        updatePage(option) {
            if (option === 'anterior') {
                this.pagination.page = this.pagination.page - 1
            } else {
                this.pagination.page = this.pagination.page + 1
            }
            this.initialize()
        },
        updatePerPage() {
            this.pagination.page = 1
            this.initialize()
        },
        toggleDialogEditShipped(shipped) {
            this.editShipped = shipped;
            this.temporaryCancelFlag = this.editShipped.paymentCancelFlag;
            this.temporaryCancelRequester = this.editShipped.paymentCancelRequester;
            this.temporaryPaymentUpdatePrice = this.editShipped.paymentUpdatePrice;
            this.temporaryPaymentUpdateRequester = this.editShipped.paymentUpdateRequester;
            this.temporaryNewValueFlag = this.editShipped.paymentUpdatePrice != null;
            this.dialogDetail = true
        },
        checkShipping() {
            let request = {
                url: api.orderHistoricShipped() + '/check/' + this.editShipped.reference
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let requestData = response.data
                        if (requestData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Conferido com sucesso!',
                                type: 'success'
                            });
                            this.editShipped.checked = true;
                            this.dialogDetail = false;
                            this.initialize()
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao conferir!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        updateEditShipped() {
            let loader = this.$loading.show();
            let request = {
                url: api.orderHistoricShipped() + '/notes/' + this.editShipped.reference,
                data: {
                    recibo: this.editShipped.recibo,
                    notes: this.editShipped.notes,
                    notesPri: this.editShipped.notesPri,
                    payCheck: this.editShipped.payCheck,
                    checkNumber: this.editShipped.checkNumber,
                    checkAccount: this.editShipped.checkAccount,
                    checkValue: this.editShipped.checkValue,
                    checkDate: this.editShipped.checkDate,
                    paymentCancelFlag: this.temporaryCancelFlag,
                    paymentCancelRequester: this.temporaryCancelRequester,
                    paymentCancelDate: this.paymentCancelDate,
                    courier: this.editShipped.courier,
                    shippingDate: this.editShipped.created_at,
                    paymentUpdatePrice: this.temporaryPaymentUpdatePrice,
                    paymentUpdateRequester: this.temporaryPaymentUpdateRequester,
                    paymentUpdateDate: this.paymentUpdateDate,
                    cttEmail: this.temporaryCTTemail,
                    price: this.editShipped.price
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            if (this.temporaryCancelFlag == true) {
                                this.editShipped.paymentCancelDate = responseData.data.paymentCancelDate;
                                this.editShipped.paymentCancelLogin = responseData.data.paymentCancelLogin;
                            }
                            if (this.temporaryNewValueFlag == true) {
                                this.editShipped.paymentUpdateDate = responseData.data.paymentUpdateDate;
                                this.editShipped.paymentUpdateLogin = responseData.data.paymentUpdateLogin;
                            }
                            this.editShipped.paymentUpdatePrice = responseData.data.paymentUpdatePrice;
                            this.editShipped.paymentUpdateRequester = responseData.data.paymentUpdateRequester;

                            this.editShipped.paymentCancelFlag = responseData.data.paymentCancelFlag;
                            this.editShipped.paymentCancelRequester = responseData.data.paymentCancelRequester;


                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Notas guardadas!',
                                type: 'success'
                            });
                            this.dialogDetail = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao guardar notas!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        clearFilters() {
            this.filters = {
                date: '',
                entity: '',
                name: '',
                invoice: '',
                courier: '',
                reference: '',
                tracking: '',
                checked: '',
                reembolso: '',
                price: '',
                recibo: ''
            };
            this.pagination.page = 1;
            this.initialize();
        },
        setFiltersCourier(courier) {
            this.filters = {
                date: '',
                entity: '',
                name: '',
                invoice: '',
                courier: courier,
                reference: '',
                tracking: '',
                checked: '',
                reembolso: '1',
                reembolsoUpdate: '',
                price: '',
                recibo: '0'
            };
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        dowloadPackingList(shipping) {
            let loader = this.$loading.show();
            let docType = shipping.hasPackingList.split('/')[0]
            let docNumber = shipping.hasPackingList.split('/')[1]

            //por cada caixa
            for (let i = 1; i <= parseInt(shipping.quantity); i++) {
                let filename = 'packList_' + docType + '_' + docNumber + '_box_' + i + '.pdf';

                //Fazer pedido do file
                let request = {
                    url: api.orderPackingListPDFFile() + '/' + docType + '/' + docNumber + '/' + i
                }

                ApiWS.dispatch('makeDownload', request)
                        .then(response => {
                            var file = new Blob([response.data], {
                                type: 'application/pdf'
                            });
                            var fileURL = URL.createObjectURL(file);
                            var a = document.createElement('a');
                            a.href = fileURL;
                            a.target = '_blank';
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);

                            //if last box, stop loading
                            if (i == parseInt(shipping.quantity))
                                loader.hide()
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        mapStatusTransnautica(status){
            let auxStatus = '';
            switch (status) {
                case 'OUT':
                    auxStatus = 'Outbound';
                    break;
                case 'INB':
                    auxStatus = 'Inbound';
                    break;
                case 'CRE':
                    auxStatus = 'Criada';
                    break;
                case 'CAN':
                    auxStatus = 'Cancelada';
                    break;
                case 'REC':
                    auxStatus = 'Recolha';
                    break;
                case 'WHS':
                    auxStatus = 'Armazém';
                    break;
                case 'DIS':
                    auxStatus = 'Distribuição';
                    break;
                case 'DEL':
                    auxStatus = 'Entregue';
                    break;
                default:
                    auxStatus = status;
                    break;
            }
            return auxStatus;
        }
    }
}
</script>
